import * as React from 'react';

import { AppPageLayout, AppPageLinks } from 'src/app.consumer/components';
import { Localized, Typography } from 'src/app/components';
import { AppProps, SeoObject } from 'src/app/types';

const seo: SeoObject = {
  title: 'Not found',
};

// TODO: i18n
const NotFoundPage = (props: AppProps) => {
  return (
    <AppPageLayout
      sidebar={<AppPageLinks />}
      subTitle={'Not found'}
      title={<Localized dictKey={'staticLocales:pages./not-found.link label'} />}
      {...{ ...props, seo }}
    >
      <Typography variant={'h1'} renderAs={'h4'}>
        404 - Seite nicht gefunden
      </Typography>
      <Typography variant={'body1'}>Hier fehlen uns die Worte..</Typography>
    </AppPageLayout>
  );
};

export default NotFoundPage;
